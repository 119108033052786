import React from 'react';

import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { PromptContainer, ScreenContainer } from 'components';
import { isNotNullOrUndefined } from 'utils/checks';

export type TroubleshootScreenVariant = 'terminal-offline';

export type TroubleshootScreenProps = {
  variant: TroubleshootScreenVariant;
  onConfirm?: () => void;
};

const getTitle = ({ variant }: TroubleshootScreenProps): React.JSX.Element | undefined => {
  switch (variant) {
    case 'terminal-offline':
      return <>Is your card machine connected?</>;
  }
};

const getSubTitle = ({ variant }: TroubleshootScreenProps): React.JSX.Element | undefined => {
  switch (variant) {
    case 'terminal-offline':
      return (
        <>
          Check that it&#39;s <b>switched on</b>, the <b>battery is at least 5% and charging</b>,
          and it&#39;s <b>connected to the Internet</b>.
        </>
      );
  }
};

const getConfirm = ({ variant }: TroubleshootScreenProps): React.JSX.Element | undefined => {
  switch (variant) {
    case 'terminal-offline':
      return <>Yes</>;
  }
};

export const TroubleshootScreen: React.FC<TroubleshootScreenProps> = ({
  variant,
  onConfirm,
}: TroubleshootScreenProps) => {
  const title = getTitle({ variant });
  const subtitle = getSubTitle({ variant });
  const confirm = getConfirm({ variant });

  return (
    <ScreenContainer center={true}>
      <PromptContainer titleElement={title} subtitleElement={subtitle}>
        {isNotNullOrUndefined(confirm) && (
          <VStack>
            <Box height={10} />
            <HStack justify={'center'}>
              <Button
                colorPalette={'conclusion'}
                onClick={onConfirm}
                width={['2xs', 'xs', 'sm', 'md']}>
                {confirm}
              </Button>
            </HStack>
          </VStack>
        )}
      </PromptContainer>
    </ScreenContainer>
  );
};
