import React from 'react';

import { FillGradientContainer, FillLogoContainer } from 'components';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { ErrorScreen } from 'screens';

const getProblemMessage = (error: unknown): string => {
  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 404:
        return 'The page you are looking for does not exist.';

      case 500:
        return 'An unexpected error occurred. Please try again later.';

      default:
        return 'An error occurred.';
    }
  }

  return 'Something went wrong';
};

export type ErrorRouteProps = {
  error?: unknown;
};

export const ErrorRoute: React.FC = ({ error }: ErrorRouteProps) => {
  const routeError = useRouteError();
  const problem = getProblemMessage(routeError ?? error);
  console.error(routeError ?? error);

  return (
    <FillGradientContainer>
      <FillLogoContainer>
        <ErrorScreen problem={<>{problem}</>} />
      </FillLogoContainer>
    </FillGradientContainer>
  );
};
