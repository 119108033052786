import { useOutletContext } from 'react-router-dom';
import { PaymentRequestSessionState } from 'types';

export type SessionStateContext = {
  sessionState: PaymentRequestSessionState;
  refreshSessionState: () => Promise<void>;
};

export const useSessionState = () => {
  return useOutletContext<SessionStateContext>();
};
