import { createIcon } from '@chakra-ui/react';

export const TimeCircleIcon = createIcon({
  displayName: 'TimeCircleIcon',
  viewBox: '0 0 72 73',
  path: (
    <g>
      <path
        id='Path'
        d='M48.199 36.8576H34.2432'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <circle
        id='Oval'
        cx='36.0508'
        cy='35.0498'
        r='27.0112'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        id='Path_2'
        d='M34.2433 36.8572V20.0435'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </g>
  ),
  defaultProps: {
    width: '72px',
    height: '73px',
  },
});
