import { createIcon } from '@chakra-ui/react';

export const PencilIcon = createIcon({
  displayName: 'PencilIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        d='M3 21V16.75L17.625 2.17499L21.8 6.44999L7.25 21H3ZM17.6 7.79999L19 6.39999L17.6 4.99999L16.2 6.39999L17.6 7.79999Z'
        fill='currentColor'
      />
    </g>
  ),
  defaultProps: {
    width: '24px',
    height: '24px',
  },
});
