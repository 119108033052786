export class ApiError extends Error {
  constructor(
    message: string,
    public cause?: unknown,
  ) {
    super(message);
  }
}

export class TerminalOfflineError extends ApiError {
  constructor(public cause?: unknown) {
    super('Terminal offline.', cause);
  }
}

export class SessionStateFetchInProgressError extends ApiError {
  constructor(public cause?: unknown) {
    super('A session state fetch is already in progress.', cause);
  }
}

export class FetchSessionStateError extends ApiError {
  constructor(public cause?: unknown) {
    super('Failed to fetch session state.', cause);
  }
}

export class ConfirmPaymentSessionError extends ApiError {
  constructor(public cause?: unknown) {
    super('Failed to confirm payment session.', cause);
  }
}
