import { createIcon } from '@chakra-ui/react';

export const CrossCircleIcon = createIcon({
  displayName: 'CrossCircleIcon',
  viewBox: '0 0 80 80',
  path: (
    <g>
      <path
        d='M49.4281 30.5721L30.572 49.4282'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M49.428 49.4282L30.5719 30.5721'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40 70V70C23.43 70 10 56.57 10 40V40C10 23.43 23.43 10 40 10V10C56.57 10 70 23.43 70 40V40C70 56.57 56.57 70 40 70Z'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </g>
  ),
  defaultProps: {
    width: '80px',
    height: '80px',
  },
});
