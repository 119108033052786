import { defineRecipe } from '@chakra-ui/react';

export const buttonRecipe = defineRecipe({
  base: {
    transition: 'all 0.2s cubic-bezier(.08,.52,.52,1)',
    colorPalette: 'primary',
  },
  variants: {
    variant: {
      solid: {
        fontFamily: 'Inter',
        fontWeight: 'medium',
        borderRadius: 'full',
        color: 'colorPalette.button.solid.color',
        background: 'colorPalette.button.solid.background',
        _hover: {
          color: 'colorPalette.button.solid._hover.color',
          background: 'colorPalette.button.solid._hover.background',
        },
        _active: {
          color: 'colorPalette.button.solid._active.color',
          background: 'colorPalette.button.solid._active.background',
        },
        _disabled: {
          color: 'colorPalette.button.solid._disabled.color',
          background: 'colorPalette.button.solid._disabled.background',
          borderColor: 'colorPalette.button.solid._disabled.borderColor',
          borderWidth: '1px',
        },
      },
      outline: {
        fontFamily: 'Inter',
        fontWeight: 'medium',
        borderRadius: 'full',
        color: 'colorPalette.button.outline.color',
        background: 'colorPalette.button.outline.background',
        borderColor: 'colorPalette.button.outline.borderColor',
        borderWidth: '1px',
        _hover: {
          color: 'colorPalette.button.outline._hover.color',
          background: 'colorPalette.button.outline._hover.background',
        },
        _active: {
          color: 'colorPalette.button.outline._active.color',
          background: 'colorPalette.button.outline._active.background',
        },
        _disabled: {
          color: 'colorPalette.button.outline._disabled.color',
          background: 'colorPalette.button.outline._disabled.background',
          borderColor: 'colorPalette.button.outline._disabled.borderColor',
          borderWidth: '1px',
        },
      },
    },
    size: {
      sm: {
        fontSize: 'sm',
        minHeight: '2.25rem',
        paddingX: '2.5',
      },
      md: {
        fontSize: 'md',
        minHeight: '3.25rem',
        paddingX: '4',
      },
      lg: {
        fontSize: 'lg',
        minHeight: '4rem',
        paddingX: '6',
      },
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'solid',
  },
});
