import { createIcon } from '@chakra-ui/react';

export const ChevronLeftIcon = createIcon({
  displayName: 'ChevronLeftIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path d='M14 18L8 12L14 6L15.4 7.4L10.8 12L15.4 16.6L14 18Z' fill='currentColor' />
    </g>
  ),
  defaultProps: {
    width: '24px',
    height: '24px',
  },
});
