import { createIcon } from '@chakra-ui/react';

export const ExclamationCircleIcon = createIcon({
  displayName: 'ExclamationCircleIcon',
  viewBox: '0 0 80 80',
  path: (
    <g>
      <path
        fill='none'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40 10V10C56.57 10 70 23.43 70 40V40C70 56.57 56.57 70 40 70V70C23.43 70 10 56.57 10 40V40C10 23.43 23.43 10 40 10Z'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fill='none'
        d='M40.0002 41.6667V25'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fill='none'
        d='M39.9968 53.3333C39.5368 53.3333 39.1635 53.7067 39.1668 54.1667C39.1668 54.6267 39.5402 55 40.0002 55C40.4602 55 40.8335 54.6267 40.8335 54.1667C40.8335 53.7067 40.4602 53.3333 39.9968 53.3333'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  ),
  defaultProps: {
    width: '80px',
    height: '80px',
  },
});
