import React from 'react';

import { Spinner } from '@chakra-ui/react';
import { ScreenContainer, StatusContainer } from 'components';

export type PaymentProcessingScreenProps = {
  status?: string;
  displayAmount?: string;
};

export const PaymentProcessingScreen: React.FC<PaymentProcessingScreenProps> = ({
  status,
  displayAmount,
}: PaymentProcessingScreenProps) => {
  return (
    <ScreenContainer center={true}>
      <StatusContainer title={<>{displayAmount}</>} subtitle={<>{status}</>}>
        <Spinner borderWidth='2px' animationDuration='0.65s' color='currentColor' size='xl' />
      </StatusContainer>
    </ScreenContainer>
  );
};
