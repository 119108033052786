import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: __SENTRY_DSN__,
  environment: __SENTRY_ENV__,
  release: __SENTRY_RELEASE__,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  sendDefaultPii: true,
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\/api/],
});

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
