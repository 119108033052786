import React from 'react';

import { Container } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { RouterProvider, ShouldRevalidateFunctionArgs } from 'react-router-dom';
import {
  ConfirmPaymentRoute,
  ErrorRoute,
  IndexRoute,
  PaymentProcessingRoute,
  PaymentResultRoute,
  paymentResultRouteLoader,
  PaymentRoute,
  paymentRouteLoader,
  RootRoute,
} from 'routes';
import { sentryCreateBrowserRouter } from 'utils/sentry';

const router = sentryCreateBrowserRouter(
  [
    {
      path: '/',
      element: <RootRoute />,
      errorElement: <ErrorRoute />,
      children: [
        { index: true, element: <IndexRoute /> },
        {
          path: 'payments/:paymentSessionId',
          element: <PaymentRoute />,
          loader: paymentRouteLoader,
          children: [
            {
              path: 'confirm',
              element: <ConfirmPaymentRoute />,
            },
            {
              path: 'processing',
              element: <PaymentProcessingRoute />,
            },
            {
              path: 'result',
              loader: paymentResultRouteLoader,
              shouldRevalidate: ({ currentUrl, nextUrl }: ShouldRevalidateFunctionArgs) => {
                console.log({ currentUrl: currentUrl.pathname, nextUrl: nextUrl.pathname });
                return (
                  currentUrl?.pathname != nextUrl?.pathname &&
                  nextUrl?.pathname?.match(/\/payments\/.*\/result/g)
                );
              },
              element: <PaymentResultRoute />,
            },
          ],
        },
      ],
    },
  ],
  {
    future: {
      v7_startTransition: true,
      v7_fetcherPersist: true,
      v7_relativeSplatPath: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionStatusRevalidation: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);

export const AppRouter: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorRoute />} showDialog>
      <Container width='100vw' height='100vh' maxWidth='unset' padding='unset'>
        <RouterProvider router={router} future={{ v7_startTransition: true }} />
      </Container>
    </Sentry.ErrorBoundary>
  );
};
