import React from 'react';

import { ErrorScreen } from 'screens';

export const IndexRoute: React.FC = () => {
  return (
    <ErrorScreen
      problem={<>{'You have reached the wrong page.'}</>}
      suggestion={<>{'Try starting the payment again.'}</>}
    />
  );
};
