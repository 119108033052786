import React from 'react';

import { Center, Flex, FlexProps } from '@chakra-ui/react';
import { LogoIcon } from 'components';

export type LogoContainerProps = {
  children?: React.ReactNode;
} & FlexProps;

export const FillLogoContainer: React.FC<LogoContainerProps> = ({ children, ...props }) => {
  return (
    <Flex
      data-testid={'fill-logo-container'}
      direction={'column'}
      overflow={'auto'}
      position={'absolute'}
      left={0}
      right={0}
      top={0}
      bottom={0}>
      <Flex grow={'1'} direction={'column'} {...props}>
        {children}
      </Flex>
      <Center paddingBottom='6'>
        <LogoIcon />
      </Center>
    </Flex>
  );
};
