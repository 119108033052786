import { createIcon } from '@chakra-ui/react';

export const ConfirmCircleIcon = createIcon({
  displayName: 'ConfirmCircleIcon',
  viewBox: '0 0 56 57',
  path: (
    <g>
      <path
        d='M17.5 29.9004L24.5 36.9004L38.15 22.9004'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        color='currentColor'
        fill='none'
      />
      <circle cx='28' cy='28.5' r='26' stroke='currentColor' strokeWidth='4' fill='none' />
    </g>
  ),
  defaultProps: {
    width: '56px',
    height: '57px',
  },
});
