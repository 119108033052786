import React from 'react';

import { isNotNullOrUndefined } from 'utils/checks';

export type IntervalPollingOptions = {
  timeout?: number;
  onError?: (error: unknown) => 'stop-polling' | 'continue-polling';
};

export const useIntervalPolling = (
  fn: () => Promise<void>,
  { timeout, onError }: IntervalPollingOptions,
) => {
  const timerIdRef = React.useRef<number | null>(null);

  const [running, setRunning] = React.useState(true);

  React.useEffect(() => {
    const pollingCallback = async () => {
      try {
        await fn();
      } catch (error) {
        if (isNotNullOrUndefined(onError)) {
          if (onError(error) == 'stop-polling') {
            setRunning(false);
          }
        }
      }
    };

    const startInterval = () => {
      timerIdRef.current = window.setInterval(pollingCallback, timeout ?? 1000);
    };

    const stopInterval = () => {
      if (isNotNullOrUndefined(timerIdRef.current)) {
        window.clearInterval(timerIdRef.current);
      }
    };

    if (running) {
      startInterval();
    } else {
      stopInterval();
    }

    return stopInterval;
  }, [timeout, running, fn, onError]);

  return {
    pollingEnabled: running,
    setPollingEnabled: setRunning,
  };
};
