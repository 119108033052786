import { createIcon } from '@chakra-ui/react';

export const LogoIcon = createIcon({
  displayName: 'LogoIcon',
  viewBox: '0 0 105 34',
  path: (
    <path
      clipRule='evenodd'
      d='M37.3401 17.0872C37.3401 19.7467 38.9182 21.04 41.564 21.04C44.2097 21.04 45.8342 19.8932 45.8342 17.0872C45.8342 14.208 44.2097 12.9635 41.587 12.9635C38.9413 12.9635 37.3401 14.2812 37.3401 17.0629V17.0872ZM41.6362 7.22015C43.6914 7.22015 45.5263 7.60883 47.0923 8.36031C50.2731 9.91486 52.1572 12.8431 52.1572 17.0148V17.1184C52.1572 19.295 51.7167 21.0829 50.8358 22.534C49.0743 25.4101 45.8445 26.7836 41.587 26.7836C39.4341 26.7836 37.5745 26.4467 35.9596 25.747C32.7788 24.3478 31.0171 21.5494 31.0171 17.0927V17.0407C31.0171 14.9677 31.482 13.2057 32.3628 11.7547C34.1734 8.80077 37.4277 7.22015 41.6362 7.22015ZM21.9662 14.6507L26.1845 7.68661H33.1888L25.0648 21.0058V26.2654H18.6334V20.9798L10.9 7.68661H17.9302L21.9662 14.6507ZM82.7063 21.04C80.0604 21.04 78.4824 19.7467 78.4824 17.0872V17.0629C78.4824 14.2812 80.0837 12.9635 82.7294 12.9635C85.3519 12.9635 86.9766 14.208 86.9766 17.0872C86.9766 19.8932 85.3519 21.04 82.7063 21.04ZM88.2347 8.36031C86.6688 7.60883 84.8337 7.22015 82.7785 7.22015C78.5702 7.22015 75.3159 8.80077 73.5053 11.7547C72.6243 13.2057 72.1596 14.9677 72.1596 17.0407V17.0927C72.1596 21.5494 73.9211 24.3478 77.1021 25.747C78.7168 26.4467 80.5764 26.7836 82.7295 26.7836C86.9868 26.7836 90.2168 25.4101 91.9783 22.534C92.859 21.0829 93.2997 19.295 93.2997 17.1184V17.0148C93.2997 12.8431 91.4155 9.91486 88.2347 8.36031ZM54.7606 11.7547C56.5713 8.80077 59.8254 7.22015 64.0339 7.22015C66.089 7.22015 67.9242 7.60883 69.4899 8.36031C69.9968 8.608 70.4704 8.89085 70.9089 9.20781V15.0877C70.9089 15.0877 67.6976 12.9636 64.8597 12.9636C62.2138 12.9636 60.0876 14.2813 60.0876 17.0629V17.0874C60.0876 19.747 62.1907 21.04 64.8364 21.04C67.7408 21.04 70.9089 18.9163 70.9089 18.9163V24.9695C69.1248 26.1884 66.7781 26.7836 63.9849 26.7836C61.8317 26.7836 59.9721 26.4469 58.3574 25.7472C55.1766 24.348 53.4149 21.5494 53.4149 17.0927V17.0407C53.4149 14.9679 53.8799 13.2059 54.7606 11.7547Z'
      fill='currentColor'
    />
  ),
  defaultProps: {
    width: '105px',
    height: '34px',
  },
});
