import { defineTokens } from '@chakra-ui/react';

export const colorTokens = defineTokens({
  colors: {
    black: { value: '#000000' },
    white: { value: '#FFFFFF' },
    brand: {
      gradient: {
        main: {
          linear: {
            from: { value: '#BCE5FAFF' },
            to: { value: '#F8E1DAFF' },
          },
          radian: {
            from: { value: '#CFEFFAFF' },
            to: { value: '#CFEFFA00' },
          },
          mask: {
            from: { value: '#FFFFFF00' },
            mid: { value: '#FFFFFF00' },
            to: { value: '#FFFFFFFF' },
          },
        },
      },
    },
    background: {
      primary: { value: '#FFFFFF' },
      secondary: { value: '#F6F4F1' },
      tertiary: { value: '#e9e9e9' },
      accent: { value: '#0099E0' },
      accentPrimary: { value: '#D4EEF9' },
      confirmation: { value: '#014737' },
      confirmationPrimary: { value: '#e4ecea' },
      warning: { value: '#ff9e00' },
      warningPrimary: { value: '#ffeed1' },
      critical: { value: '#9E0015' },
      criticalPrimary: { value: '#ffd1d7' },
    },
    backgroundInverse: {
      primary: { value: '#292724' },
      secondary: { value: '#2b2b2b' },
      tertiary: { value: '#3E3C39' },
      accent: { value: '#004666' },
      critical: { value: '#70000E' },
    },
    content: {
      primary: { value: '#222222' },
      secondary: { value: '#4e4e4e' },
      tertiary: { value: '#767676' },
      disabled: { value: '#D4D2CF' },
      accent: { value: '#006593' },
      confirmation: { value: '#026F56' },
      warning: { value: '#FF9E00' },
      critical: { value: '#EB001F' },
    },
    contentInverse: {
      primary: { value: '#FFFFFF' },
      secondary: { value: '#d3d3d3' },
      tertiary: { value: '#ababab' },
      disabled: { value: '#767676' },
      accent: { value: '#63C0EC' },
      confirmation: { value: '#FFFFFF' },
      warning: { value: '#FFFFFF' },
      critical: { value: '#FFA8B4' },
    },
    border: {
      primary: { value: '#e9e9e9' },
      secondary: { value: '#D4D2CF' },
      tertiary: { value: '#ababab' },
      transparent: { value: 'rgba(0, 0, 0, 0.09)' },
      selected: { value: '#292724' },
      accent: { value: '#00a9e0' },
      confirmation: { value: '#029c79' },
      warning: { value: '#ff9e00' },
      critical: { value: '#eb001f' },
    },
    borderInverse: {
      primary: { value: '#383838' },
      secondary: { value: '#4e4e4e' },
      tertiary: { value: '#767676' },
      transparent: { value: 'rgba(255, 255, 255, 0.09)' },
      selected: { value: '#ffffff' },
    },
    overlay: {
      primary: { value: 'rgba(0, 0, 0, 0.09)' },
      secondary: { value: 'rgba(0, 0, 0, 0.17)' },
      tertiary: { value: 'rgba(0, 0, 0, 0.33)' },
    },
  },
});
