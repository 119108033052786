import React from 'react';

import { Button, Link, Spinner, VStack } from '@chakra-ui/react';
import {
  ChevronLeftIcon,
  ExclamationCircleIcon,
  ScreenContainer,
  StatusContainer,
} from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import { isNotNullOrUndefined } from 'utils/checks';

export type ErrorScreenProps = {
  title?: React.ReactNode;
  problem?: React.ReactNode;
  suggestion?: React.ReactNode;
  allowRetry?: boolean;
  onRetry?: () => Promise<void>;
  allowBack?: boolean;
  onBack?: () => Promise<void>;
};

export const ErrorScreen: React.FC<ErrorScreenProps> = ({
  title,
  problem,
  suggestion,
  allowRetry = true,
  onRetry,
  allowBack = false,
  onBack,
}) => {
  const { paymentSessionId } = useParams();
  const navigate = useNavigate();
  const [retrying, setRetrying] = React.useState(false);
  const showRetry =
    allowRetry && (isNotNullOrUndefined(paymentSessionId) || isNotNullOrUndefined(onRetry));
  const showBack =
    allowBack && (isNotNullOrUndefined(paymentSessionId) || isNotNullOrUndefined(onBack));

  return (
    <ScreenContainer center={true} gap={4}>
      <StatusContainer title={title ?? <>{'Oops'}</>} subtitle={problem} status={suggestion}>
        <ExclamationCircleIcon color='content.critical' />
      </StatusContainer>

      <VStack gap={4} justify={'center'}>
        {showRetry && (
          <Button
            colorPalette={'conclusion'}
            disabled={retrying}
            minWidth={['2xs', 'xs', 'sm']}
            onClick={async () => {
              if (isNotNullOrUndefined(onRetry)) {
                try {
                  setRetrying(true);
                  await onRetry();
                } finally {
                  setRetrying(false);
                }
              } else {
                navigate(`/payments/${paymentSessionId}`);
              }
            }}>
            {!retrying ? (
              'Try again'
            ) : (
              <>
                <Spinner
                  borderWidth='2px'
                  animationDuration='0.65s'
                  color='currentColor'
                  size='md'
                />
                {'Retrying...'}
              </>
            )}
          </Button>
        )}

        {showBack && (
          <Link
            onClick={async () => {
              if (!retrying) {
                if (isNotNullOrUndefined(onBack)) {
                  await onBack();
                } else {
                  navigate(`/payments/${paymentSessionId}`);
                }
              }
            }}>
            <ChevronLeftIcon /> {'Go back'}
          </Link>
        )}
      </VStack>
    </ScreenContainer>
  );
};
