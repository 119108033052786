import { defineTokens } from '@chakra-ui/react';

export const semanticColorTokens = defineTokens({
  colors: {
    primary: {
      button: {
        solid: {
          color: { value: '{colors.contentInverse.primary}' },
          background: { value: '{colors.backgroundInverse.primary}' },
          _hover: {
            color: { value: '{colors.contentInverse.accent}' },
            background: { value: '{colors.backgroundInverse.primary}' },
          },
          _active: {
            color: { value: '{colors.contentInverse.accent}' },
            background: { value: '{colors.backgroundInverse.tertiary}' },
          },
          _disabled: {
            color: { value: '{colors.content.disabled}' },
            background: { value: '{colors.background.primary}' },
            borderColor: { value: '{colors.border.secondary}' },
          },
        },
        outline: {
          color: { value: '{colors.content.primary}' },
          background: { value: 'unset' },
          borderColor: { value: '{colors.border.selected}' },
          _hover: {
            color: { value: '{colors.content.primary}' },
            background: { value: '{colors.background.tertiary}' },
          },
          _active: {
            color: { value: '{colors.content.primary}' },
            background: { value: '{colors.background.accentPrimary}' },
          },
          _disabled: {
            color: { value: '{colors.content.disabled}' },
            background: { value: 'unset' },
            borderColor: { value: '{colors.border.secondary}' },
          },
        },
      },
    },
    conclusion: {
      button: {
        solid: {
          color: { value: '{colors.contentInverse.primary}' },
          background: { value: '{colors.background.accent}' },
          _hover: {
            color: { value: '{colors.contentInverse.primary}' },
            background: { value: '{colors.content.accent}' },
          },
          _active: {
            color: { value: '{colors.contentInverse.primary}' },
            background: { value: '{colors.backgroundInverse.accent}' },
          },
          _disabled: {
            color: { value: '{colors.content.disabled}' },
            background: { value: '{colors.background.primary}' },
            borderColor: { value: '{colors.border.secondary}' },
          },
        },
      },
    },
    critical: {
      button: {
        solid: {
          color: { value: '{colors.contentInverse.primary}' },
          background: { value: '{colors.background.critical}' },
          _hover: {
            color: { value: '{colors.contentInverse.critical}' },
            background: { value: '{colors.content.critical}' },
          },
          _active: {
            color: { value: '{colors.contentInverse.critical}' },
            background: { value: '{colors.backgroundInverse.critical}' },
          },
          _disabled: {
            color: { value: '{colors.content.disabled}' },
            background: { value: '{colors.background.primary}' },
            borderColor: { value: '{colors.border.secondary}' },
          },
        },
      },
    },
  },
});
