import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

type GradientLayerProps = {
  gradient?: string;
  borderRadius?: string;
  children?: React.ReactNode;
} & BoxProps;

const GradientLayer: React.FC<GradientLayerProps> = ({
  gradient,
  borderRadius,
  children,
  ...props
}) => {
  return (
    <Box
      position='absolute'
      left={0}
      right={0}
      top={0}
      bottom={0}
      style={{ background: gradient }}
      borderRadius={borderRadius}
      {...props}>
      {children}
    </Box>
  );
};

export type GradientContainer = {
  children?: React.ReactNode;
} & BoxProps;

export const FillGradientContainer: React.FC<GradientContainer> = ({ children, ...props }) => {
  return (
    <Box
      data-testid={'fill-gradient-container'}
      position='absolute'
      left={0}
      right={0}
      top={0}
      bottom={0}
      {...props}>
      <GradientLayer gradient='linear-gradient(90deg, var(--chakra-colors-brand-gradient-main-linear-from) 0%, var(--chakra-colors-brand-gradient-main-linear-to) 100%)' />
      <GradientLayer
        gradient='radial-gradient(37.47% 50% at 50% 50%, var(--chakra-colors-brand-gradient-main-radial-from) 0%, var(--chakra-colors-brand-gradient-main-radial-to) 100%)'
        borderRadius='100%'
      />
      <GradientLayer gradient='linear-gradient(180deg, var(--chakra-colors-brand-gradient-main-mask-from) 0%, var(--chakra-colors-brand-gradient-main-mask-mid) 33.33%, var(--chakra-colors-brand-gradient-main-mask-to) 100%)' />{' '}
      {children != null && <GradientLayer>{children}</GradientLayer>}
    </Box>
  );
};
