import React from 'react';

import { Button, Card, HStack, Separator, VStack } from '@chakra-ui/react';
import { ChevronRightIcon, PromptContainer, ScreenContainer, TerminalBox } from 'components';
import { ClientTerminal } from 'types';

export type ChangeTerminalScreen = {
  terminals: ClientTerminal[];
  onCancel: () => void;
  onChange: (terminal: ClientTerminal) => void;
};

export const ChangeTerminalScreen: React.FC<ChangeTerminalScreen> = ({
  terminals,
  onCancel,
  onChange,
}: ChangeTerminalScreen) => {
  return (
    <ScreenContainer data-testid={'change-terminal-screen'}>
      <PromptContainer
        titleElement={<>Choose another card machine.</>}
        subtitleElement={<>These are the available card machines.</>}>
        <Card.Root>
          <VStack align={'stretch'} separator={<Separator />}>
            {terminals.map((terminal) => (
              <TerminalBox
                key={terminal.uuid}
                terminal={terminal}
                onClick={() => {
                  onChange(terminal);
                }}
                padding={'1'}>
                <ChevronRightIcon />
              </TerminalBox>
            ))}
          </VStack>
        </Card.Root>

        <HStack justify={'center'}>
          <Button data-testid={'cancel'} onClick={onCancel}>
            Cancel
          </Button>
        </HStack>
      </PromptContainer>
    </ScreenContainer>
  );
};
