import 'utils/sentry';

import { StrictMode } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { AppRouter, Fonts } from 'app';
import { ThemeProvider } from 'next-themes';
import { createRoot } from 'react-dom/client';
import { YocoTheme } from 'theme';

const container = document.getElementById('root');

if (!container) {
  throw new Error('Failed to find the root element');
}

createRoot(container).render(
  <StrictMode>
    <ChakraProvider value={YocoTheme}>
      <ThemeProvider attribute={'class'} disableTransitionOnChange defaultTheme={'light'}>
        <Fonts />
        <AppRouter />
      </ThemeProvider>
    </ChakraProvider>
  </StrictMode>,
);
