import React from 'react';

import { Box, BoxProps, Button, Flex, HStack, Image, Spacer, Stack, Text } from '@chakra-ui/react';
import { BatteryIcon } from 'components';
import { ClientTerminal, ClientTerminalStatus, TerminalModel } from 'types';
import { isNotNullOrUndefined } from 'utils/checks';

const TerminalModelImage: React.FC<{ terminal: ClientTerminal; size: number[] }> = ({
  terminal,
  size,
}) => {
  const imageUrl = new Map<TerminalModel, string>([
    [TerminalModel.NEO_TOUCH, '/assets/images/neo-touch.png'],
  ]).get(terminal.model);

  return (
    isNotNullOrUndefined(imageUrl) && (
      <Image
        data-testid={'terminal-model-image'}
        width={size.map((it) => (isNotNullOrUndefined(it) ? `${it}px` : null))}
        margin={size.map((it) => (isNotNullOrUndefined(it) ? `${it * -0.2}px` : null))}
        src={imageUrl}
        alt={terminal.model}
      />
    )
  );
};

const TerminalModelText: React.FC<{ terminal: ClientTerminal }> = ({ terminal }) => {
  switch (terminal.model) {
    case TerminalModel.NEO_TOUCH:
      return (
        <Text data-testid={'terminal-model-text'} fontFamily={'Inter'} lineHeight={'none'}>
          Neo Touch
        </Text>
      );
    default:
      throw new Error(`Unexpected terminal model '${terminal.model}'`);
  }
};

const TerminalSerialNumberText: React.FC<{ terminal: ClientTerminal }> = ({ terminal }) => {
  return (
    <Text
      data-testid={'terminal-serial-number-text'}
      fontSize={['normal', null, 'xl']}
      fontWeight={'medium'}
      lineHeight={'tall'}
      whiteSpace={'nowrap'}>
      {terminal.serialNumber}
    </Text>
  );
};

const TerminalBatteryLevel: React.FC<{ terminal: ClientTerminal }> = ({ terminal }) => {
  if (
    terminal.status == ClientTerminalStatus.CONNECTED &&
    isNotNullOrUndefined(terminal.batteryLevel)
  ) {
    return (
      <Text
        data-testid={'terminal-battery-level-text'}
        fontSize={'xs'}
        opacity={0.5}
        color={terminal.batteryLevel < 10 ? 'content.critical' : 'content.primary'}>
        <BatteryIcon aspectRatio={1} height={'4'} /> {`${terminal.batteryLevel} %`}
      </Text>
    );
  }

  return null;
};

export type TerminalBoxProps = {
  children?: React.ReactNode;
  terminal: ClientTerminal;
  onClick?: () => void;
  direction?: 'column' | 'row';
} & BoxProps;

const TerminalBoxContent: React.FC<TerminalBoxProps> = ({
  terminal,
  children,
  direction = 'row',
  ...props
}) => {
  return (
    <Box data-testid={'terminal-box'} data-terminal-uuid={terminal?.uuid} {...props}>
      <Flex
        flexWrap={'wrap'}
        paddingX={'3'}
        paddingY={'2'}
        gap={'2'}
        align={'center'}
        justify={'end'}
        direction={direction}>
        {isNotNullOrUndefined(terminal) && (
          <Stack gap={'4'} direction={direction} alignItems={'center'}>
            <TerminalModelImage
              terminal={terminal}
              size={direction == 'row' ? [100] : [100, 200]}
            />
            <Stack
              alignItems={direction == 'row' ? 'start' : 'center'}
              gap={'2'}
              direction={direction == 'row' ? 'column' : 'row'}>
              <TerminalModelText terminal={terminal} />
              <HStack>
                <TerminalSerialNumberText terminal={terminal} />
                <TerminalBatteryLevel terminal={terminal} />
              </HStack>
            </Stack>
          </Stack>
        )}

        {isNotNullOrUndefined(children) && (
          <>
            <Spacer />
            <Box>{children}</Box>
          </>
        )}
      </Flex>
    </Box>
  );
};

export const TerminalBox: React.FC<TerminalBoxProps> = ({ onClick, ...props }) => {
  return (
    <>
      {isNotNullOrUndefined(onClick) ? (
        <Button variant={'plain'} height={'unset'} display={'unset'} onClick={onClick}>
          <TerminalBoxContent {...props} />
        </Button>
      ) : (
        <Box>
          <TerminalBoxContent {...props} />
        </Box>
      )}
    </>
  );
};
