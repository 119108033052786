/**
 * Represents an amount of money with its currency type.
 *
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.Amount
 */
export type Amount = {
  /**
   * The three-letter currency code defined in ISO 4217.
   *
   * @generated from protobuf field: string currency_code = 1;
   */
  currencyCode: string;

  /**
   * The amount of money in smallest currency unit.
   * For example if `currencyCode` is `"ZAR"`, then a value of 100 is one Rand.
   *
   * @generated from protobuf field: int64 units = 2;
   */
  units: number;
};

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.Business
 */
export type Business = {
  /**
   * @generated from protobuf field: string uuid = 1;
   */
  uuid: string;

  /**
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string;
};

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.Location
 */
export type Location = {
  /**
   * The latitude in degrees. It must be in the range [-90.0, +90.0].
   *
   * @generated from protobuf field: double latitude = 1;
   */
  latitude: number;

  /**
   * The longitude in degrees. It must be in the range [-180.0, +180.0].
   *
   * @generated from protobuf field: double longitude = 2;
   */
  longitude: number;

  /**
   * The horizontal accuracy in meters of this location.
   *
   * @generated from protobuf field: optional double accuracy = 3;
   */
  accuracy?: number;
};

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.CardDetails
 */
export type CardDetails = {
  /**
   * The cardholder's card scheme (e.g. Visa, Mastercard, Diners, etc).
   *
   * @generated from protobuf field: string scheme = 1;
   */
  scheme: string;

  /**
   * The masked card number showing the first 6 numbers and the last 4.
   *
   * @generated from protobuf field: string masked_pan = 2;
   */
  maskedPan: string;

  /**
   * The expiry year of the card.
   *
   * @generated from protobuf field: int32 expiry_year = 3;
   */
  expiryYear: number;

  /**
   * The expiry month of year of card (must be from 1 to 12).
   *
   * @generated from protobuf field: int32 expiry_month = 4;
   */
  expiryMonth: number;

  /**
   * How the card was presented to terminal. e.g. CONTACTLESS_ICC if the card was tapped.
   *
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.CardDetails.PanEntryMode pan_entry_mode = 5;
   */
  panEntryMode: PanEntryMode;

  /**
   * How the cardholder was verified by terminal.
   *
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.CardDetails.CardholderVerificationMethod cardholder_verification_method = 6;
   */
  cardholderVerificationMethod: CardholderVerificationMethod;
};

/**
 * @generated from protobuf enum com.yoco.cloud.payments.client.api.v1.CardDetails.PanEntryMode
 */
export enum PanEntryMode {
  /**
   * @generated from protobuf enum value: UNKNOWN = 0;
   */
  UNKNOWN = 'UNKNOWN',

  /**
   * @generated from protobuf enum value: MANUAL = 1;
   */
  MANUAL = 'MANUAL',

  /**
   * @generated from protobuf enum value: MAGNETIC_STRIPE = 2;
   */
  MAGNETIC_STRIPE = 'MAGNETIC_STRIPE',

  /**
   * @generated from protobuf enum value: BAR_CODE = 3;
   */
  BAR_CODE = 'BAR_CODE',

  /**
   * @generated from protobuf enum value: OCR = 4;
   */
  OCR = 'OCR',

  /**
   * @generated from protobuf enum value: CHIP_WITH_CVV = 5;
   */
  CHIP_WITH_CVV = 'CHIP_WITH_CVV',

  /**
   * @generated from protobuf enum value: CONTACTLESS_ICC = 7;
   */
  CONTACTLESS_ICC = 'CONTACTLESS_ICC',

  /**
   * @generated from protobuf enum value: MAG_STRIPE_WITH_CVV = 90;
   */
  MAG_STRIPE_WITH_CVV = 'MAG_STRIPE_WITH_CVV',

  /**
   * @generated from protobuf enum value: CONTACTLESS_MAG_STRIPE = 91;
   */
  CONTACTLESS_MAG_STRIPE = 'CONTACTLESS_MAG_STRIPE',

  /**
   * @generated from protobuf enum value: CHIP_WITHOUT_CVV = 95;
   */
  CHIP_WITHOUT_CVV = 'CHIP_WITHOUT_CVV',
}

/**
 * @generated from protobuf enum com.yoco.cloud.payments.client.api.v1.CardDetails.CardholderVerificationMethod
 */
export enum CardholderVerificationMethod {
  /**
   * @generated from protobuf enum value: FAILED = 0;
   */
  FAILED = 'FAILED',

  /**
   * @generated from protobuf enum value: OFFLINE_PLAIN_TEXT_PIN = 1;
   */
  OFFLINE_PLAIN_TEXT_PIN = 'OFFLINE_PLAIN_TEXT_PIN',

  /**
   * @generated from protobuf enum value: ONLINE_PIN = 2;
   */
  ONLINE_PIN = 'ONLINE_PIN',

  /**
   * @generated from protobuf enum value: OFFLINE_PLAIN_TEXT_PIN_AND_SIGNATURE = 3;
   */
  OFFLINE_PLAIN_TEXT_PIN_AND_SIGNATURE = 'OFFLINE_PLAIN_TEXT_PIN_AND_SIGNATURE',

  /**
   * @generated from protobuf enum value: OFFLINE_ENCIPHERED_PIN = 4;
   */
  OFFLINE_ENCIPHERED_PIN = 'OFFLINE_ENCIPHERED_PIN',

  /**
   * @generated from protobuf enum value: OFFLINE_ENCIPHERED_PIN_AND_SIGNATURE = 5;
   */
  OFFLINE_ENCIPHERED_PIN_AND_SIGNATURE = 'OFFLINE_ENCIPHERED_PIN_AND_SIGNATURE',

  /**
   * @generated from protobuf enum value: PAPER_BASED_SIGNATURE_ONLY = 30;
   */
  PAPER_BASED_SIGNATURE_ONLY = 'PAPER_BASED_SIGNATURE_ONLY',

  /**
   * @generated from protobuf enum value: APPROVE_CVM_PROCESSING = 31;
   */
  APPROVE_CVM_PROCESSING = 'APPROVE_CVM_PROCESSING',

  /**
   * @generated from protobuf enum value: NO_CVM_PERFORMED = 63;
   */
  NO_CVM_PERFORMED = 'NO_CVM_PERFORMED',
}

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.EmvApplicationInfo
 */
export type EmvApplicationInfo = {
  /**
   * The EMV Application ID (AID) selected for the payment.
   *
   * @generated from protobuf field: string application_identifier = 1;
   */
  applicationIdentifier: string;

  /**
   * The preferred name for the EMV application.
   *
   * @generated from protobuf field: string application_name = 2;
   */
  applicationName: string;
};

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.PosDevice
 */
export type PosDevice = {
  /**
   * @generated from protobuf field: string uuid = 1;
   */
  uuid: string;

  /**
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.Business business = 3;
   */
  business: Business;
};

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.RegisterPosDeviceRequest
 */
export type RegisterPosDeviceRequest = {
  /**
   * @generated from protobuf field: string display_name = 1;
   */
  displayName: string;
};

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.PosLinkDetails
 */
export type PosLinkDetails = {
  /**
   * @generated from protobuf field: string uuid = 1;
   */
  uuid: string;

  /**
   * @generated from protobuf field: string pos_device_uuid = 2;
   */
  posDeviceUuid: string;

  /**
   * @generated from protobuf field: string pos_device_name = 3;
   */
  posDeviceName: string;
};

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.TerminalOnlineStatus
 */
export type TerminalOnlineStatus = {
  /**
   * @generated from protobuf field: bool is_connected = 1;
   */
  isConnected: boolean;

  /**
   * @generated from protobuf field: bool is_busy = 2;
   */
  isBusy: boolean;

  /**
   * @generated from protobuf field: optional bool battery_charging = 3;
   */
  batteryCharging?: boolean;

  /**
   * @generated from protobuf field: optional int32 battery_percentage = 4;
   */
  batteryPercentage?: number;
};

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.Terminal
 */
export type Terminal = {
  /**
   * @generated from protobuf field: string uuid = 1;
   */
  uuid: string;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.Terminal.Status status = 2;
   */
  status: TerminalStatus;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.TerminalModel model = 3;
   */
  model: TerminalModel;

  /**
   * @generated from protobuf field: string serial_number = 4;
   */
  serialNumber: string;

  /**
   * @generated from protobuf field: optional com.yoco.cloud.payments.client.api.v1.Business business = 5;
   */
  business?: Business;

  /**
   * @generated from protobuf field: optional com.yoco.cloud.payments.client.api.v1.PosLinkDetails linked_pos = 6;
   */
  linkedPos?: PosLinkDetails;

  /**
   * @generated from protobuf field: optional com.yoco.cloud.payments.client.api.v1.TerminalOnlineStatus online_status = 7;
   */
  onlineStatus?: TerminalOnlineStatus;
};

/**
 * @generated from protobuf enum com.yoco.cloud.payments.client.api.v1.Terminal.Status
 */
export enum TerminalStatus {
  /**
   * @generated from protobuf enum value: STATUS_UNSPECIFIED = 0;
   */
  STATUS_UNSPECIFIED = 0,

  /**
   * @generated from protobuf enum value: ACTIVE = 1;
   */
  ACTIVE = 1,
}

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.ListTerminalsResponse
 */
export type ListTerminalsResponse = {
  /**
   * @generated from protobuf field: repeated com.yoco.cloud.payments.client.api.v1.Terminal terminals = 1;
   */
  terminals: Terminal[];
};

/**
 * @generated from protobuf enum com.yoco.cloud.payments.client.api.v1.TerminalModel
 */
export enum TerminalModel {
  /**
   * @generated from protobuf enum value: NEO_TOUCH = 1;
   */
  NEO_TOUCH = 'NEO_TOUCH',

  /**
   * @generated from protobuf enum value: KHUMO = 2;
   */
  KHUMO = 'KHUMO',

  /**
   * @generated from protobuf enum value: KHUMO_PRINT = 3;
   */
  KHUMO_PRINT = 'KHUMO_PRINT',
}

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.PosTerminalLink
 */
export type PosTerminalLink = {
  /**
   * @generated from protobuf field: string uuid = 1;
   */
  uuid: string;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.PosTerminalLink.Status status = 2;
   */
  status: TerminalLinkStatus;

  /**
   * @generated from protobuf field: optional string status_message = 3;
   */
  statusMessage?: string;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.PosDevice pos_device = 4;
   */
  posDevice: PosDevice;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.Terminal terminal = 5;
   */
  terminal: Terminal;
};

/**
 * @generated from protobuf enum com.yoco.cloud.payments.client.api.v1.PosTerminalLink.Status
 */
export enum TerminalLinkStatus {
  /**
   * @generated from protobuf enum value: PENDING_CREATION = 1;
   */
  PENDING_CREATION = 'PENDING_CREATION',

  /**
   * @generated from protobuf enum value: PENDING_DISCONNECT = 2;
   */
  PENDING_DISCONNECT = 'PENDING_DISCONNECT',

  /**
   * @generated from protobuf enum value: CREATED = 3;
   */
  CREATED = 'CREATED',

  /**
   * @generated from protobuf enum value: FAILED = 4;
   */
  FAILED = 'FAILED',

  /**
   * @generated from protobuf enum value: DISCONNECTED = 5;
   */
  DISCONNECTED = 'DISCONNECTED',
}

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.ListPosTerminalLinksResponse
 */
export type ListPosTerminalLinksResponse = {
  /**
   * @generated from protobuf field: repeated com.yoco.cloud.payments.client.api.v1.PosTerminalLink pos_terminal_link = 1;
   */
  posTerminalLink: PosTerminalLink[];
};

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.PosPayment
 */
export type PosPayment = {
  /**
   * @generated from protobuf field: string uuid = 1;
   */
  uuid: string;

  /**
   * @generated from protobuf field: string request_uuid = 2;
   */
  requestUuid: string;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.PosPayment.Status status = 3;
   */
  status: PosPaymentStatus;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.Amount amount = 4;
   */
  amount: Amount;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.PosTerminalLink pos_terminal_link = 5;
   */
  posTerminalLink: PosTerminalLink;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.PosPaymentRequest.Options request_options = 6;
   */
  requestOptions?: PosPaymentRequestOptions;

  /**
   * @generated from protobuf field: optional com.yoco.cloud.payments.client.api.v1.PosPayment.Flow flow = 7;
   */
  flow?: PosPaymentFlow;

  /**
   * @generated from protobuf field: optional com.yoco.cloud.payments.client.api.v1.PosPayment.CancelStatus cancel_status = 8;
   */
  cancelStatus?: PosPaymentCancelStatus;

  /**
   * @generated from protobuf field: optional com.yoco.cloud.payments.client.api.v1.PosPayment.Result result = 9;
   */
  result?: PosPaymentResult;

  /**
   * @generated from protobuf field: optional com.yoco.cloud.payments.client.api.v1.Amount tip_amount = 11;
   */
  tipAmount?: Amount;

  /**
   * @generated from protobuf field: optional com.yoco.cloud.payments.client.api.v1.PosPayment.FailureResult failure_result = 12;
   */
  failureResult?: PosPaymentFailureResult;

  /**
   * @generated from protobuf field: map<string, string> metadata = 10;
   */
  metadata: {
    [key: string]: string;
  };
};

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.PosPayment.Flow
 */
export type PosPaymentFlow = {
  /**
   * Current state in the payment flow.
   *
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.PosPayment.Flow.State state = 1;
   */
  state: PosPaymentFlowState;

  /**
   * Optional display message.
   *
   * @generated from protobuf field: optional string message = 2;
   */
  message?: string;
};

/**
 * @generated from protobuf enum com.yoco.cloud.payments.client.api.v1.PosPayment.Flow.State
 */
export enum PosPaymentFlowState {
  /**
   * The terminal is initialising payment flow.
   *
   * @generated from protobuf enum value: INITIALISING = 9;
   */
  INITIALISING = 'INITIALISING',

  /**
   * The terminal is waiting for a possible tip.
   *
   * @generated from protobuf enum value: ASK_FOR_TIP = 1;
   */
  ASK_FOR_TIP = 'ASK_FOR_TIP',

  /**
   * The terminal is waiting for customer to present card.
   *
   * @generated from protobuf enum value: WAITING_FOR_CARD = 2;
   */
  WAITING_FOR_CARD = 'WAITING_FOR_CARD',

  /**
   * The customer has presented their card to terminal.
   *
   * @generated from protobuf enum value: CARD_PRESENTED = 10;
   */
  CARD_PRESENTED = 'CARD_PRESENTED',

  /**
   * The terminal is waiting for the customer to select a payment application.
   *
   * @generated from protobuf enum value: WAITING_FOR_APP_SELECTION = 3;
   */
  WAITING_FOR_APP_SELECTION = 'WAITING_FOR_APP_SELECTION',

  /**
   * The terminal is waiting for the customer to enter their PIN.
   *
   * @generated from protobuf enum value: WAITING_FOR_PIN = 4;
   */
  WAITING_FOR_PIN = 'WAITING_FOR_PIN',

  /**
   * The terminal is requesting payment authorisation.
   *
   * @generated from protobuf enum value: AUTHORISING = 5;
   */
  AUTHORISING = 'AUTHORISING',

  /**
   * The terminal is finalizing payment.
   *
   * @generated from protobuf enum value: FINALIZING = 11;
   */
  FINALIZING = 'FINALIZING',

  /**
   * The terminal has prompted the customer to provide their signature.
   *
   * @generated from protobuf enum value: WAITING_FOR_SIGNATURE = 6;
   */
  WAITING_FOR_SIGNATURE = 'WAITING_FOR_SIGNATURE',

  /**
   * The terminal is printing the receipt.
   *
   * @generated from protobuf enum value: PRINTING_RECEIPT = 7;
   */
  PRINTING_RECEIPT = 'PRINTING_RECEIPT',

  /**
   * The terminal has completed the payment.
   *
   * @generated from protobuf enum value: COMPLETED = 8;
   */
  COMPLETED = 'COMPLETED',
}

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.PosPayment.Result
 */
export type PosPaymentResult = {
  /**
   * The description of the completed payment status.
   * e.g. LIMIT EXCEEDED if the payment failed because of an exceeded limit.
   *
   * @generated from protobuf field: string outcome = 1;
   */
  outcome: string;

  /**
   * The total amount charged from the cardholder (bill amount + tip amount).
   *
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.Amount final_amount = 2;
   */
  finalAmount: Amount;

  /**
   * The Retrieval Reference Number (RRN) is the reference code that can be used to refer
   * to the transaction when there's any inquiry for this transaction.
   *
   * @generated from protobuf field: string retrieval_reference_number = 3;
   */
  retrievalReferenceNumber: string;

  /**
   * A Unique identifier for this merchant that performed this transaction.
   *
   * @generated from protobuf field: string merchant_id = 4;
   */
  merchantId: string;

  /**
   * A Unique identifier for the terminal that performed this transaction.
   *
   * @generated from protobuf field: string terminal_id = 5;
   */
  terminalId: string;

  /**
   * An identifier that uniquely identifies this transaction's authorization.
   *
   * @generated from protobuf field: string authorization_id = 6;
   */
  authorizationId: string;

  /**
   * The EMV transaction authorization code.
   *
   * @generated from protobuf field: string authorization_code = 7;
   */
  authorizationCode: string;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.CardDetails card_details = 8;
   */
  cardDetails: CardDetails;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.EmvApplicationInfo emv_application_info = 9;
   */
  emvApplicationInfo: EmvApplicationInfo;

  /**
   * Transaction Time
   *
   * @generated from protobuf field: int64 payment_flow_start_time = 10;
   */
  paymentFlowStartTime: number;
};

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.PosPayment.FailureResult
 */
export type PosPaymentFailureResult = {
  /**
   * Most appropriate reason why this payment failed.
   *
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.PosPayment.FailureResult.FailureReason reason = 1;
   */
  reason: PosPaymentFailureReason;

  /**
   * Optional display message.
   *
   * @generated from protobuf field: optional string message = 2;
   */
  message?: string;
};

/**
 * @generated from protobuf enum com.yoco.cloud.payments.client.api.v1.PosPayment.Status
 */
export enum PosPaymentStatus {
  /**
   * @generated from protobuf enum value: PENDING = 1;
   */
  PENDING = 'PENDING',

  /**
   * @generated from protobuf enum value: SUCCESSFUL = 2;
   */
  SUCCESSFUL = 'SUCCESSFUL',

  /**
   * @generated from protobuf enum value: FAILED = 3;
   */
  FAILED = 'FAILED',
}

/**
 * @generated from protobuf enum com.yoco.cloud.payments.client.api.v1.PosPayment.CancelStatus
 */
export enum PosPaymentCancelStatus {
  /**
   * @generated from protobuf enum value: REQUESTED = 1;
   */
  REQUESTED = 'REQUESTED',

  /**
   * @generated from protobuf enum value: SUCCESS = 2;
   */
  SUCCESS = 'SUCCESS',

  /**
   * @generated from protobuf enum value: FAILED_UNABLE_TO_CANCEL = 3;
   */
  FAILED_UNABLE_TO_CANCEL = 'FAILED_UNABLE_TO_CANCEL',
}

/**
 * @generated from protobuf enum com.yoco.cloud.payments.client.api.v1.PosPayment.FailureResult.FailureReason
 */
export enum PosPaymentFailureReason {
  /**
   * Payment was cancelled - either by customer or via API (presence of cancel_status would indicate which).
   *
   * @generated from protobuf enum value: CANCELLED = 1;
   */
  CANCELLED = 'CANCELLED',

  /**
   * Payment timed out - most likely waiting for customer input (present card or PIN).
   *
   * @generated from protobuf enum value: TIMEOUT = 2;
   */
  TIMEOUT = 'TIMEOUT',

  /**
   * Payment was declined.
   *
   * @generated from protobuf enum value: DECLINE = 3;
   */
  DECLINE = 'DECLINE',

  /**
   * Payment failed due to an (internal) error - more detail may be available in message field.
   *
   * @generated from protobuf enum value: ERROR = 4;
   */
  ERROR = 'ERROR',
}

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.PosPaymentRequest
 */
export type PosPaymentRequest = {
  /**
   * @generated from protobuf field: string request_uuid = 1;
   */
  requestUuid: string;

  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.Amount amount = 2;
   */
  amount: Amount;

  /**
   * The linked terminal to process the payment.
   *
   * @generated from protobuf field: optional string terminal_uuid = 3;
   */
  terminalUuid?: string;

  /**
   * @generated from protobuf field: optional string pos_terminal_link_uuid = 5;
   */
  posTerminalLinkUuid?: string;

  /**
   * @generated from protobuf field: optional com.yoco.cloud.payments.client.api.v1.PosPaymentRequest.Options options = 4;
   */
  options?: PosPaymentRequestOptions;

  /**
   * @generated from protobuf field: map<string, string> metadata = 10;
   */
  metadata: {
    [key: string]: string;
  };
};

/**
 * @generated from protobuf message com.yoco.cloud.payments.client.api.v1.PosPaymentRequest.Options
 */
export type PosPaymentRequestOptions = {
  /**
   * @generated from protobuf field: com.yoco.cloud.payments.client.api.v1.Amount tip_amount = 1;
   */
  tipAmount?: Amount;

  /**
   * @generated from protobuf field: bool ask_for_tip = 2;
   */
  askForTip?: boolean;

  /**
   * An arbitrary string attached to the payment.
   *
   * @generated from protobuf field: optional string description = 3;
   */
  description?: string;

  /**
   * @generated from protobuf field: optional string staff_id = 4;
   */
  staffId?: string;

  /**
   * @generated from protobuf field: optional string staff_name = 5;
   */
  staffName?: string;

  /**
   * @generated from protobuf field: optional com.yoco.cloud.payments.client.api.v1.Location pos_location = 6;
   */
  posLocation?: Location;
};

/**
 * @generated from protobuf enum com.yoco.cloud.payments.client.api.v1.ProblemTypes
 */
export enum ProblemTypes {
  // Fallback
  UNKNOWN = 'UNKNOWN',

  /**
   * @generated from protobuf enum value: ENTITY_NOT_FOUND = 1;
   */
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',

  /**
   * @generated from protobuf enum value: UNAUTHORIZED_POS_DEVICE = 2;
   */
  UNAUTHORIZED_POS_DEVICE = 'UNAUTHORIZED_POS_DEVICE',

  /**
   * @generated from protobuf enum value: UNABLE_TO_CANCEL_PAYMENT = 3;
   */
  UNABLE_TO_CANCEL_PAYMENT = 'UNABLE_TO_CANCEL_PAYMENT',

  /**
   * @generated from protobuf enum value: UNAUTHENTICATED = 4;
   */
  UNAUTHENTICATED = 'UNAUTHENTICATED',

  /**
   * @generated from protobuf enum value: INVALID_CREDENTIALS = 5;
   */
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',

  /**
   * @generated from protobuf enum value: TERMINAL_OFFLINE = 6;
   */
  TERMINAL_OFFLINE = 'TERMINAL_OFFLINE',

  /**
   * @generated from protobuf enum value: INTERNAL_API_ERROR = 7;
   */
  INTERNAL_API_ERROR = 'INTERNAL_API_ERROR',

  /**
   * @generated from protobuf enum value: TERMINAL_NOT_LINKED = 8;
   */
  TERMINAL_NOT_LINKED = 'TERMINAL_NOT_LINKED',

  /**
   * @generated from protobuf enum value: TERMINAL_BUSY = 9;
   */
  TERMINAL_BUSY = 'TERMINAL_BUSY',

  /**
   * @generated from protobuf enum value: DUPLICATE_REQUEST = 10;
   */
  DUPLICATE_REQUEST = 'DUPLICATE_REQUEST',

  /**
   * @generated from protobuf enum value: TERMINAL_NOT_ALLOCATED = 11;
   */
  TERMINAL_NOT_ALLOCATED = 'TERMINAL_NOT_ALLOCATED',
}

/**
 * Protobuf version of RFC7807 (https://datatracker.ietf.org/doc/html/rfc7807).
 * also known as the "Problem Details for HTTP APIs" specification, defines a standardized format for
 * describing errors or problems that occur in HTTP APIs.
 *
 * @generated from protobuf message yoco.api.v1.ProblemDetail
 */
export type ProblemDetail = {
  /**
   * A URI reference that identifies the problem type.
   * If not present, its value is assumed to be "about:blank".
   *
   * @generated from protobuf field: string type = 1;
   */
  type: string;

  /**
   * A short, human-readable summary of the problem type.
   * It SHOULD NOT change from occurrence to occurrence of the problem, except for purposes of localization.
   *
   * @generated from protobuf field: string title = 2;
   */
  title: string;

  /**
   * The HTTP status code.
   *
   * @generated from protobuf field: int32 status = 3;
   */
  status: number;

  /**
   * A human-readable explanation specific to this occurrence of the problem.
   *
   * @generated from protobuf field: optional string detail = 4;
   */
  detail?: string;

  /**
   * A URI reference that identifies the specific occurrence of the problem.
   *
   * @generated from protobuf field: optional string instance = 5;
   */
  instance?: string;
};
