import React from 'react';

import { HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import { isNotNullOrUndefined } from 'utils/checks';

export type PromptContainerProps = {
  children?: React.ReactNode;
  titleElement?: React.ReactNode;
  subtitleElement?: React.ReactNode;
} & StackProps;

export const PromptContainer: React.FC<PromptContainerProps> = ({
  children,
  titleElement,
  subtitleElement,
  ...props
}) => {
  return (
    <VStack data-testid={'prompt-container'} gap={'6'} align={'normal'} {...props} padding={'4'}>
      {isNotNullOrUndefined(titleElement) && (
        <HStack justify={'center'}>
          <Text fontSize={'2xl'} fontWeight={'bolder'} lineHeight={'tall'} textAlign={'center'}>
            {titleElement}
          </Text>
        </HStack>
      )}
      {isNotNullOrUndefined(subtitleElement) && (
        <HStack justify={'center'}>
          <Text
            fontSize={'sm'}
            fontWeight={'medium'}
            lineHeight={'short'}
            letterSpacing={'wide'}
            textAlign={'center'}>
            {subtitleElement}
          </Text>
        </HStack>
      )}
      {children}
    </VStack>
  );
};
