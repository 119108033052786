import { createIcon } from '@chakra-ui/react';

export const StopCircleIcon = createIcon({
  displayName: 'StopCircleIcon',
  viewBox: '0 0 80 80',
  path: (
    <g>
      <circle
        id='Oval'
        cx='40'
        cy='40'
        r='30'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        id='Path'
        d='M60.0356 20.0312L20.0356 60.0312'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </g>
  ),
  defaultProps: {
    width: '80px',
    height: '80px',
  },
});
