import React from 'react';

import { FillGradientContainer, FillLogoContainer } from 'components';
import { Outlet } from 'react-router-dom';

export const RootRoute: React.FC = () => {
  return (
    <FillGradientContainer>
      <FillLogoContainer
        maxWidth={['unset', null, '2xl']}
        marginX={[4, null, 'auto']}
        marginTop={[4, null, 9]}>
        <Outlet />
      </FillLogoContainer>
    </FillGradientContainer>
  );
};
