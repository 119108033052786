import React from 'react';

import { Text, VStack } from '@chakra-ui/react';
import { isNotNullOrUndefined } from 'utils/checks';

export type StatusContainerProps = {
  children?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  status?: React.ReactNode;
};

export const StatusContainer: React.FC<StatusContainerProps> = ({
  children,
  title,
  subtitle,
  status,
  ...props
}) => {
  return (
    <VStack data-testid={'status-container'} gap={4} align='center' {...props}>
      {children}

      {isNotNullOrUndefined(title) && (
        <Text fontSize={'2xl'} fontWeight={'bolder'} lineHeight={'tall'} textAlign={'center'}>
          {title}
        </Text>
      )}

      {isNotNullOrUndefined(subtitle) && (
        <Text
          fontSize={'sm'}
          fontWeight={'medium'}
          lineHeight={'short'}
          letterSpacing={'wide'}
          textAlign={'center'}>
          {subtitle}
        </Text>
      )}

      {isNotNullOrUndefined(status) && (
        <Text
          fontSize={'2xs'}
          fontWeight={'normal'}
          lineHeight={'shorter'}
          letterSpacing={'wide'}
          textAlign={'center'}>
          {status}
        </Text>
      )}
    </VStack>
  );
};
