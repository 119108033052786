import { createIcon } from '@chakra-ui/react';

export const ChevronRightIcon = createIcon({
  displayName: 'ChevronRightIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path d='M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z' fill='currentColor' />
    </g>
  ),
  defaultProps: {
    width: '24px',
    height: '24px',
  },
});
