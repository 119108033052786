import React from 'react';

import { Button, HStack, Text } from '@chakra-ui/react';
import {
  ConfirmCircleIcon,
  CrossCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  ScreenContainer,
  StatusContainer,
  StopCircleIcon,
  TimeCircleIcon,
} from 'components';
import { PaymentProcessingResult, PosPaymentFailureReason } from 'types';
import { isNotNullOrUndefined } from 'utils/checks';

export type PaymentResultScreenProps = {
  paymentResult: PaymentProcessingResult;
  displayAmount: string;
  onClose?: () => void;
};

export const PaymentResultScreen: React.FC<PaymentResultScreenProps> = ({
  paymentResult,
  displayAmount,
  onClose,
}: PaymentResultScreenProps) => {
  return (
    <ScreenContainer center={true} gap={6}>
      {(() => {
        if (paymentResult.result?.outcome === 'APPROVED') {
          return (
            <StatusContainer title={<>{'Payment approved'}</>} subtitle={<>{displayAmount}</>}>
              <ConfirmCircleIcon color='content.confirmation' />
            </StatusContainer>
          );
        } else if (isNotNullOrUndefined(paymentResult.failureReason)) {
          switch (paymentResult.failureReason) {
            case PosPaymentFailureReason.DECLINE:
              return (
                <StatusContainer title={<>{'Payment declined'}</>}>
                  <StopCircleIcon color='content.critical' />
                </StatusContainer>
              );
            case PosPaymentFailureReason.ERROR:
              return (
                <StatusContainer
                  title={<>{'Payment unsuccessful'}</>}
                  subtitle={<>{displayAmount}</>}>
                  <ExclamationTriangleIcon color='content.critical' />
                </StatusContainer>
              );
            case PosPaymentFailureReason.TIMEOUT:
              return (
                <StatusContainer title={<>{'Payment timed out'}</>}>
                  <TimeCircleIcon color='content.critical' />
                </StatusContainer>
              );
            case PosPaymentFailureReason.CANCELLED:
              return (
                <StatusContainer title={<>{'Payment cancelled'}</>}>
                  <CrossCircleIcon color='content.critical' />
                </StatusContainer>
              );
          }
        }

        return (
          <StatusContainer
            title={'Something unexpected happened'}
            subtitle={
              'Please check the card machine or Sales History to see if the transaction completed. If the error persists, contact Yoco Support.'
            }>
            <ExclamationCircleIcon color='content.critical' />
          </StatusContainer>
        );
      })()}
      <HStack justify={'center'}>
        {isNotNullOrUndefined(onClose) ? (
          <Button
            colorPalette={'conclusion'}
            minWidth={['2xs', 'xs', 'sm']}
            onClick={() => {
              onClose();
            }}>
            Close
          </Button>
        ) : (
          <Text
            fontSize={'sm'}
            fontWeight={'medium'}
            lineHeight={'short'}
            letterSpacing={'wide'}
            textAlign={'center'}>
            It is now safe to close your browser window or tab.
          </Text>
        )}
      </HStack>
    </ScreenContainer>
  );
};
