import { defineConfig } from '@chakra-ui/react';

import { colorTokens } from './ColorTokens';
import { fontSizeTokens } from './FontSizeTokens';
import { fontTokens } from './FontTokens';
import { fontWeightTokens } from './FontWeightTokens';
import { letterSpacingTokens } from './LetterSpacingTokens';
import { lineHeightTokens } from './LineHeightTokens';
import { semanticColorTokens } from './SemanticColorTokens';

export const themeTokenConfig = defineConfig({
  theme: {
    tokens: {
      ...colorTokens,
      ...fontSizeTokens,
      ...fontTokens,
      ...fontWeightTokens,
      ...letterSpacingTokens,
      ...lineHeightTokens,
    },
    semanticTokens: {
      ...semanticColorTokens,
    },
  },
});
