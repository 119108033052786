import {
  PosPaymentFailureReason,
  PosPaymentFlowState,
  PosPaymentResult,
  TerminalModel,
} from './cloud_payments_client';

export enum PaymentRequestSessionStatus {
  CREATED = 'created',
  PENDING_CONFIRMATION = 'pending_confirmation',
  AWAITING_LINK = 'awaiting_link',
  AWAITING_PAYMENT_START = 'awaiting_payment_start',
  AWAITING_PAYMENT_COMPLETION = 'awaiting_payment_completion',
  COMPLETED = 'completed',
}

export type PaymentProcessingFlowState = {
  terminal: ClientTerminal;
  state?: PosPaymentFlowState;
  message?: string;
};

export type PaymentProcessingResult = {
  result?: PosPaymentResult;
  message?: string;
  failureReason?: PosPaymentFailureReason;
};

export type PaymentRequestSession = {
  id: string;
  status: PaymentRequestSessionStatus;
  businessName: string;
  displayAmount: string;
  terminals?: ClientTerminal[];
  flowState?: PaymentProcessingFlowState;
  paymentResult?: PaymentProcessingResult;
};

export enum ClientTerminalStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  BUSY = 'busy',
  UNKNOWN = 'unknown',
}

export enum ClientTerminalLinkStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type ClientTerminal = {
  uuid: string;
  model: TerminalModel;
  serialNumber: string;
  status: ClientTerminalStatus;
  linkStatus?: ClientTerminalLinkStatus;
  batteryLevel?: number;
};

export type ClientConfirmPaymentRequest = {
  terminalUuid: string;
};
