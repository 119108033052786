import React from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';

export type ScreenContainerProps = {
  children?: React.ReactNode;
  center?: boolean;
} & FlexProps;

export const ScreenContainer: React.FC<ScreenContainerProps> = ({
  center = false,
  children,
  ...props
}) => {
  return (
    <Flex
      data-testid={'screen-container'}
      grow={center ? '1' : undefined}
      direction={'column'}
      justify={center ? 'center' : undefined}
      {...props}>
      {children}
    </Flex>
  );
};
