import { createIcon } from '@chakra-ui/react';

export const ExclamationTriangleIcon = createIcon({
  displayName: 'ExclamationTriangleIcon',
  viewBox: '0 0 80 80',
  path: (
    <g>
      <path
        d='M40.0002 43.7342V31.2676'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M39.9968 53.7493C39.5368 53.7493 39.1635 54.1227 39.1668 54.5827C39.1668 55.0427 39.5402 55.416 40.0002 55.416C40.4602 55.416 40.8335 55.0427 40.8335 54.5827C40.8335 54.1227 40.4602 53.7493 39.9968 53.7493'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.7633 13.7966L72.2933 58.4766C75.26 63.67 71.51 70.1333 65.53 70.1333H14.47C8.48669 70.1333 4.73669 63.67 7.70669 58.4766L33.2367 13.7966C36.2267 8.55997 43.7733 8.55997 46.7633 13.7966Z'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </g>
  ),
  defaultProps: {
    width: '80px',
    height: '80px',
  },
});
