import React, { useState } from 'react';

import { Button, HStack, Link, Spinner, Text } from '@chakra-ui/react';
import { ChevronRightIcon, PromptContainer, ScreenContainer, TerminalBox } from 'components';
import { ClientTerminal } from 'types';
import { isNullOrUndefined } from 'utils/checks';

export type ConfirmPaymentScreenProps = {
  displayAmount: string;
  selectedTerminal: ClientTerminal | null;
  onChangeTerminal: () => void;
  onPay: () => Promise<void>;
  refresh: () => void;
};

export const ConfirmPaymentScreen: React.FC<ConfirmPaymentScreenProps> = ({
  selectedTerminal,
  onPay,
  onChangeTerminal,
  displayAmount,
  refresh,
}: ConfirmPaymentScreenProps) => {
  const [readyToPay, setReadyToPay] = useState(true);

  if (isNullOrUndefined(selectedTerminal)) {
    return (
      <ScreenContainer center={true}>
        <PromptContainer
          titleElement={<>Unable to find a card machine.</>}
          subtitleElement={<>Please configure a card machine and try again.</>}>
          <HStack justify={'center'}>
            <Button data-testid={'retry'} onClick={refresh}>
              Try again
            </Button>
          </HStack>
        </PromptContainer>
      </ScreenContainer>
    );
  }

  return (
    <ScreenContainer center={true}>
      <PromptContainer titleElement={<>Confirm payment.</>}>
        <HStack justify={'center'}>
          <TerminalBox terminal={selectedTerminal} direction={'column'}></TerminalBox>
        </HStack>
        <HStack justify={'center'}>
          <Button
            data-testid={'pay'}
            disabled={!readyToPay}
            colorPalette={'conclusion'}
            minWidth={['2xs', 'xs', 'sm']}
            onClick={async () => {
              try {
                setReadyToPay(false);
                await onPay();
              } finally {
                setReadyToPay(true);
              }
            }}>
            <HStack justify={'center'} gap={'3'}>
              {!readyToPay && (
                <Spinner
                  borderWidth='2px'
                  animationDuration='0.65s'
                  color='currentColor'
                  size='md'
                />
              )}
              <Text>Pay</Text>
              <Text data-testid={'display-amount'} fontWeight={'bold'}>
                {displayAmount}
              </Text>
            </HStack>
          </Button>
        </HStack>
        <HStack justify={'center'}>
          <Link data-testid={'change-terminal'} onClick={onChangeTerminal}>
            Choose another card machine <ChevronRightIcon />
          </Link>
        </HStack>
      </PromptContainer>
    </ScreenContainer>
  );
};
