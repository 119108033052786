import React from 'react';

import { useSessionState } from 'hooks';
import { PaymentResultScreen } from 'screens';
import { isNullOrUndefined } from 'utils/checks';
import {
  canPostPaymentWindowMessage,
  PaymentWindowMessageEvent,
  postPaymentWindowMessage,
} from 'utils/window-messages';

export const paymentResultRouteLoader = async () => {
  if (canPostPaymentWindowMessage()) {
    postPaymentWindowMessage({ event: PaymentWindowMessageEvent.PAYMENT_COMPLETED });
  }
  return null;
};

export const PaymentResultRoute: React.FC = () => {
  const { sessionState } = useSessionState();

  if (isNullOrUndefined(sessionState.session)) {
    throw new Error('Missing session.');
  } else if (isNullOrUndefined(sessionState.session.paymentResult)) {
    throw new Error('Missing payment result.');
  }

  return (
    <PaymentResultScreen
      displayAmount={sessionState.session.displayAmount}
      paymentResult={sessionState.session.paymentResult}
      onClose={
        canPostPaymentWindowMessage()
          ? () => {
              postPaymentWindowMessage({
                event: PaymentWindowMessageEvent.PAYMENT_RESULT_ACKNOWLEDGED,
              });
            }
          : undefined
      }
    />
  );
};
