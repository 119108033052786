import { createIcon } from '@chakra-ui/react';

// From: https://uxwing.com/battery-charging-sign-icon/

export const BatteryIcon = createIcon({
  displayName: 'BatteryIcon',
  viewBox: '0 0 258 511',
  path: (
    <g>
      <path
        fill='currentColor'
        fillRule='nonzero'
        d='M38.493 39.897H68.08V12.088C68.08 5.435 73.517 0 80.17 0h97.662c6.627 0 12.088 5.463 12.088 12.088v27.809h29.587c21.174 0 38.493 17.34 38.493 38.493v394.616c0 21.185-17.306 38.493-38.493 38.493H38.493C17.331 511.499 0 494.149 0 473.006V78.39c0-21.195 17.299-38.493 38.493-38.493zm67.029 263.531H80.946c-7.725-.072-12.393-8.65-8.142-15.135l77.02-123.556c5.618-9.141 19.972-3.655 17.873 6.993L147.5 248.878l29.426-.497c7.582-.132 12.308 8.085 8.548 14.621l-76.049 123.597c-5.422 8.935-19.361 4.119-18.001-6.323l14.098-76.848zm111.22-238.095H41.256c-8.677 0-15.819 7.143-15.819 15.819v389.089c0 8.703 7.123 15.82 15.819 15.82h175.486c8.668 0 15.822-7.154 15.822-15.82V81.152c0-8.687-7.117-15.819-15.822-15.819z'
      />
    </g>
  ),
  defaultProps: {
    width: '12px',
    height: '24px',
  },
});
