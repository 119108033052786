import { defineConfig } from '@chakra-ui/react';

import { buttonRecipe } from './ButtonRecipe';
import { cardSlotRecipe } from './CardSlotRecipe';

export const themeRecipeConfig = defineConfig({
  theme: {
    recipes: {
      button: buttonRecipe,
    },
    slotRecipes: {
      card: cardSlotRecipe,
    },
  },
});
