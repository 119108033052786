import React from 'react';

import type { PaymentRequestSession } from 'types';

import { useIntervalPolling, useSessionState } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { PaymentProcessingScreen, TroubleshootScreen } from 'screens';
import { PaymentRequestSessionStatus } from 'types';
import { isNotNullOrUndefined } from 'utils/checks';
import {
  FetchSessionStateError,
  SessionStateFetchInProgressError,
  TerminalOfflineError,
} from 'utils/errors';

export type PaymentProcessingRouteProps = {
  pollTimeout?: number;
};

export const PaymentProcessingRoute: React.FC<PaymentProcessingRouteProps> = ({
  pollTimeout = 500,
}) => {
  const navigate = useNavigate();
  const { sessionState, refreshSessionState } = useSessionState();
  const [screen, setScreen] = React.useState<'processing' | 'terminal-offline'>('processing');
  const [status, setStatus] = React.useState<string | undefined>();

  const [pollError, setPollError] = React.useState<unknown | undefined>();
  const { setPollingEnabled } = useIntervalPolling(refreshSessionState, {
    timeout: pollTimeout,
    onError: (error) => {
      if (error instanceof FetchSessionStateError) {
        setStatus('Reconnecting...');
        return 'continue-polling';
      } else if (error instanceof TerminalOfflineError) {
        setScreen('terminal-offline');
      } else if (error instanceof SessionStateFetchInProgressError) {
        return 'continue-polling';
      } else {
        setPollError(error);
      }
      return 'stop-polling';
    },
  });

  React.useEffect(() => {
    if (pollError != null) {
      throw pollError;
    }
  }, [pollError]);

  React.useEffect(() => {
    const getFallbackMessage = (session: PaymentRequestSession) => {
      switch (session.status) {
        case PaymentRequestSessionStatus.AWAITING_LINK:
          return 'Waiting for card machine to be linked.';
        case PaymentRequestSessionStatus.AWAITING_PAYMENT_COMPLETION:
          return 'Waiting for payment to complete.';
        case PaymentRequestSessionStatus.AWAITING_PAYMENT_START:
          return 'Waiting for payment to start';
        case PaymentRequestSessionStatus.COMPLETED:
          return 'Payment completed.';
        case PaymentRequestSessionStatus.CREATED:
          return 'Payment created.';
        case PaymentRequestSessionStatus.PENDING_CONFIRMATION:
          return 'Waiting for payment confirmation.';
        default:
          return undefined;
      }
    };

    if (isNotNullOrUndefined(sessionState.session)) {
      setStatus(
        sessionState.session.flowState?.message ?? getFallbackMessage(sessionState.session),
      );
    }
  }, [sessionState, navigate]);

  switch (screen) {
    case 'processing':
      return (
        <PaymentProcessingScreen
          status={status}
          displayAmount={sessionState.session?.displayAmount}
        />
      );

    case 'terminal-offline':
      return (
        <TroubleshootScreen
          variant={'terminal-offline'}
          onConfirm={() => {
            setScreen('processing');
            setPollingEnabled(true);
          }}
        />
      );

    default:
      throw new Error(`Unexpected screen '${screen}'.`);
  }
};
