import { hcWithType } from '@repo/api/client';

import { ClientResponse } from 'hono/client';
import { ApiProblemDetail, PaymentRequestSessionState } from 'types';

import {
  ApiError,
  ConfirmPaymentSessionError,
  FetchSessionStateError,
  TerminalOfflineError,
} from './errors';

const client = hcWithType('/api/client');

const checkErrorResponse = async (action: string, response: ClientResponse<unknown>) => {
  if (!response.ok) {
    const apiProblemDetail = (await response.json()) as ApiProblemDetail;

    if (apiProblemDetail.type === 'https://yoco.dev/problems/terminal-offline') {
      throw new TerminalOfflineError(response);
    }

    throw new ApiError(`Unable to ${action}: ${apiProblemDetail.detail}`);
  }
};

export const fetchSessionState = async (
  sessionId: string,
  sessionToken: string,
): Promise<PaymentRequestSessionState> => {
  try {
    const response = await client.v1.sessions[':id'].$get(
      { param: { id: sessionId } },
      { headers: { Authorization: `Bearer ${sessionToken}` } },
    );

    await checkErrorResponse('fetch session state', response);

    const session = await response.json();

    return {
      sessionId,
      sessionToken,
      session,
    };
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    } else {
      throw new FetchSessionStateError({ cause: error });
    }
  }
};

export const confirmPaymentSession = async (
  sessionId: string,
  sessionToken: string,
  terminalUuid: string,
): Promise<PaymentRequestSessionState> => {
  try {
    const response = await client.v1.sessions[':id'].confirm.$post(
      { param: { id: sessionId }, json: { terminalUuid } },
      { headers: { Authorization: `Bearer ${sessionToken}` } },
    );

    await checkErrorResponse('confirm payment session', response);

    const session = await response.json();

    return {
      sessionId,
      sessionToken,
      session,
    };
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    } else {
      throw new ConfirmPaymentSessionError({ cause: error });
    }
  }
};
