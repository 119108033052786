import { isNotNullOrUndefined } from './checks';

export enum PaymentWindowMessageEvent {
  PAYMENT_RESULT_ACKNOWLEDGED = 'payment_result_acknowledged',
  PAYMENT_COMPLETED = 'payment_completed',
}

export type PaymentWindowMessage = {
  event: PaymentWindowMessageEvent;
};

export const canPostPaymentWindowMessage = () => {
  return isNotNullOrUndefined(getSourceWindow());
};

export const getSourceWindow = () => {
  return window.parent != window ? window.parent : null;
};

export const postPaymentWindowMessage = (message: PaymentWindowMessage) => {
  getSourceWindow()?.postMessage(message, '*');
};
