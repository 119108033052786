import { css, Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'SharpGrotesk';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/assets/fonts/SharpGrotesk-Book20.otf') format('opentype');
      }

      @font-face {
        font-family: 'SharpGrotesk';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/assets/fonts/SharpGrotesk-Medium20.otf') format('opentype');
      }

      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/assets/fonts/Inter-Regular.otf?v=3.18') format('opentype');
      }

      @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/assets/fonts/Inter-Medium.otf?v=3.18') format('opentype');
      }
    `}
  />
);
