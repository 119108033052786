import React from 'react';

import { useSessionState } from 'hooks';
import { useNavigate } from 'react-router-dom';
import {
  ChangeTerminalScreen,
  ConfirmPaymentScreen,
  ErrorScreen,
  TroubleshootScreen,
} from 'screens';
import { PaymentRequestSessionState } from 'types';
import { isNotNullOrUndefined, isNullOrUndefined } from 'utils/checks';
import { confirmPaymentSession } from 'utils/client';
import { ConfirmPaymentSessionError, TerminalOfflineError } from 'utils/errors';

const getInitialTerminal = (sessionState: PaymentRequestSessionState) => {
  const terminals = sessionState.session?.terminals;

  if (isNullOrUndefined(terminals)) {
    return null;
  }

  let initialTerminal = terminals[0] ?? null;

  const lastUsedTerminal = window.localStorage.getItem('lastUsedTerminal');

  if (isNotNullOrUndefined(lastUsedTerminal)) {
    const terminal = terminals.find((t) => t.uuid === lastUsedTerminal);

    if (isNotNullOrUndefined(terminal)) {
      initialTerminal = terminal;
    }
  }

  return initialTerminal;
};

export const ConfirmPaymentRoute: React.FC = () => {
  const navigate = useNavigate();
  const { sessionState, refreshSessionState } = useSessionState();

  if (isNullOrUndefined(sessionState.session)) {
    throw new Error('Missing session.');
  }

  const [selectedTerminal, setSelectedTerminal] = React.useState(getInitialTerminal(sessionState));
  const [screen, setScreen] = React.useState<
    'confirm' | 'confirm-error' | 'change-terminal' | 'terminal-offline'
  >('confirm');

  const confirmPayment = async () => {
    if (isNullOrUndefined(selectedTerminal)) {
      throw new Error('No terminal selected.');
    }

    try {
      await confirmPaymentSession(
        sessionState.sessionId,
        sessionState.sessionToken,
        selectedTerminal.uuid,
      );

      window.localStorage.setItem('lastUsedTerminal', selectedTerminal.uuid);

      navigate(`/payments/${sessionState.sessionId}/processing`);
    } catch (error) {
      if (error instanceof ConfirmPaymentSessionError) {
        setScreen('confirm-error');
      } else if (error instanceof TerminalOfflineError) {
        setScreen('terminal-offline');
      } else {
        throw error;
      }
    }
  };

  switch (screen) {
    case 'confirm':
      return (
        <ConfirmPaymentScreen
          displayAmount={sessionState.session.displayAmount}
          selectedTerminal={selectedTerminal}
          onChangeTerminal={() => setScreen('change-terminal')}
          refresh={refreshSessionState}
          onPay={confirmPayment}
        />
      );

    case 'confirm-error':
      return (
        <ErrorScreen
          problem={
            <>
              {'Unable to start payment of '}
              <b>{sessionState.session.displayAmount}</b>
              {' with card machine '}
              <b>{selectedTerminal?.serialNumber ?? 'unknown'}</b>
              {'.'}
            </>
          }
          allowBack={true}
          onBack={async () => {
            setScreen('confirm');
          }}
          allowRetry={true}
          onRetry={confirmPayment}
        />
      );

    case 'change-terminal':
      return (
        <ChangeTerminalScreen
          terminals={sessionState.session.terminals ?? []}
          onCancel={() => setScreen('confirm')}
          onChange={(terminal) => {
            setSelectedTerminal(terminal);
            setScreen('confirm');
          }}
        />
      );

    case 'terminal-offline':
      return (
        <TroubleshootScreen
          variant={'terminal-offline'}
          onConfirm={() => {
            setScreen('confirm');
          }}
        />
      );

    default:
      throw new Error(`Unexpected screen '${screen}'.`);
  }
};
